<!-- @format -->
<script lang="ts">
  import type { ManageAPI } from "~/manage-api/types";
  import BannerArea from "./BannerArea.svelte";

  export let banner: ManageAPI.Banner;
  
  $: bannerText = banner.text || '';
  $: desktopImageSrc = banner.desktopImageURL || '';
  $: mobileImageSrc = banner.mobileImageURL || '';
  $: href = banner.linkURL || '';
</script>

<BannerArea
  {href}
  desktopImage_src={desktopImageSrc}
  mobileImage_src={mobileImageSrc}
  text={bannerText} />
