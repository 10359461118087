<!-- @format -->
<script lang="ts">
  import { onMount } from "svelte";
  import Swiper from "swiper";
  import { Autoplay, Pagination } from "swiper/modules";
  import type { ManageAPI } from "~/manage-api/types";
  import BannerItem from "./BannerItem.svelte";
  import { generateRandomID } from "~/util/common";

  export let banners: ManageAPI.Banner[] = [];
  
  const id = generateRandomID();
  let swiperEl: HTMLElement;
  let swiperInstance: Swiper;
  let isVisible = false;
  let isInitialized = false;
  
  // Custom navigation functions
  function goNext() {
    if (swiperInstance) {
      swiperInstance.slideNext();
    }
  }

  function goPrev() {
    if (swiperInstance) {
      swiperInstance.slidePrev();
    }
  }

  onMount(() => {
    if (!swiperEl || banners.length <= 0) return;
    
    // Initialize swiper with a small delay to ensure DOM is ready
    setTimeout(() => {
      if (swiperEl) {
        swiperInstance = new Swiper(swiperEl, {
          pagination: {
            el: '.swiper-pagination',
            clickable: true,
          },
          // Navigation module removed to prevent default styles
          autoplay: { delay: 5000 },
          loop: banners.length > 1,
          modules: [Autoplay, Pagination], // Navigation module removed
          on: {
            afterInit: () => {
              isInitialized = true;
              // Make component visible after initialization
              setTimeout(() => {
                isVisible = true;
              }, 100);
            }
          }
        });
      }
    }, 50);
  });
</script>

<div class="mylly-main-banner-carousel">
  <div class="main-banner" class:is-visible={isVisible}>
    {#if banners.length === 1}
      <BannerItem banner={banners[0]} />
    {:else if banners.length > 1}
      <div class="swiper" bind:this={swiperEl} id="main-banner-container-{id}">
        <div class="swiper-wrapper">
          {#each banners as banner}
            <div class="swiper-slide">
              <BannerItem {banner} />
            </div>
          {/each}
        </div>

        <div class="navigation-controls">
          <button 
            type="button" 
            class="mylly-nav-button" 
            aria-label="Previous slide" 
            on:click={goPrev}
          >
            <!-- Previous Button SVG -->
            <svg id="nav-button-prev" data-name="Navigation Previous Button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 47.05 47.05">
              <defs>
                <style>
                  .nav-button-fill {
                    fill: rgba(255, 255, 255, 0.7);
                  }
                </style>
              </defs>
              <g id="nav-button-prev-container" data-name="Button Container">
                <path class="nav-button-fill" d="M23.52,47.05c12.99,0,23.52-10.53,23.52-23.52S36.52,0,23.52,0,0,10.53,0,23.52s10.53,23.52,23.52,23.52ZM27.41,14.22v18.6l-9.77-9.3,9.77-9.3Z"/>
              </g>
            </svg>
          </button>
          <button 
            type="button" 
            class="mylly-nav-button" 
            aria-label="Next slide" 
            on:click={goNext}
          >
            <!-- Next Button SVG -->
            <svg id="nav-button-next" data-name="Navigation Next Button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 47.05 47.05">
              <defs>
                <style>
                  .nav-button-fill {
                    fill: rgba(255, 255, 255, 0.7);
                  }
                </style>
              </defs>
              <g id="nav-button-next-container" data-name="Button Container">
                <path class="nav-button-fill" d="M23.52,0C10.53,0,0,10.53,0,23.52s10.53,23.52,23.52,23.52,23.52-10.53,23.52-23.52S36.52,0,23.52,0ZM19.64,32.83V14.22l9.77,9.3-9.77,9.3Z"/>
              </g>
            </svg>
          </button>
        </div>
        <div class="swiper-pagination"></div>
      </div>
    {/if}
  </div>
</div>

<style lang="scss">
  .mylly-main-banner-carousel {
    .main-banner {
      position: relative;
      visibility: hidden;
      opacity: 0;
      transition: opacity 0.3s ease;
      
      &.is-visible {
        visibility: visible;
        opacity: 1;
        
        /* Show navigation buttons only when carousel is visible */
        .mylly-nav-button {
          visibility: visible;
          opacity: 1;
          transition: opacity 0.3s ease, visibility 0.3s ease;
        }
      }
    }

    .swiper {
      position: relative;
    }

    /* Navigation controls container */
    .navigation-controls {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding: 0 20px;
      z-index: 10;
      pointer-events: none;
    }

    /* Custom navigation buttons */
    .mylly-nav-button {
      width: 47px;
      height: 47px;
      color: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      padding: 0;
      margin: 0;
      position: relative;
      pointer-events: auto;
      background: none;
      border: none;
      
      svg {
        width: 100%;
        height: 100%;
        filter: drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.15));
      }
    }
    
    /* Explicitly remove Swiper's default pseudo-elements */
    :global(.swiper-button-prev::after),
    :global(.swiper-button-next::after) {
      display: none;
      content: none;
      font-size: 0;
    }

    /* Pagination */
    :global(.swiper-pagination) {
      width: fit-content;
      position: absolute;
      z-index: 1;
      bottom: 10px;
      left: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      gap: 8px;
    }

    :global(.swiper-pagination-bullet) {
      width: 20px;
      height: 20px;
      background-color: #fff;
      border-radius: 50%;
    }

    :global(.swiper-pagination-bullet-active) {
      background-color: #000;
    }
  }
</style> 