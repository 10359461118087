<!-- @format -->
<script lang="ts">
  export let href: string;
  export let desktopImage_src: string;
  export let mobileImage_src: string;
  export let text: string;
</script>

{#if href}
  <a {href} class="position-relative banner-link">
    <picture>
      <source srcset={desktopImage_src} media="(min-width: 768px)" />
      <img src={mobileImage_src || desktopImage_src} alt={text} loading="eager" />
    </picture>
    {#if text}
      <div class="text">
        <div class="container-fluid container-fluid-max-xl">
          <div class="row">
            <div class="col-12 col-lg-6">
              <p>{text}</p>
            </div>
          </div>
        </div>
      </div>
    {/if}
  </a>
{:else}
  <div class="position-relative banner-container">
    <picture>
      <source srcset={desktopImage_src} media="(min-width: 768px)" />
      <img src={mobileImage_src || desktopImage_src} alt={text} loading="eager" />
    </picture>
    {#if text}
      <div class="text">
        <div class="container-fluid container-fluid-max-xl">
          <div class="row">
            <div class="col-12 col-lg-6">
              <p>{text}</p>
            </div>
          </div>
        </div>
      </div>
    {/if}
  </div>
{/if}

<style lang="scss">
  .banner-link,
  .banner-container {
    display: block;
    width: 100%;
  }
  
  .text {
    position: absolute;
    top: 66.66%;
    transform: translateY(-66.66%);
    font-weight: 700;
    font-size: 42px;
    color: #ffffff;
    width: 100%;
    line-height: 110%;
    letter-spacing: -0.03em;
    @media screen and (min-width: 768px) {
      font-size: 80px;
    }
    @media screen and (min-width: 992px) {
      font-size: 90px;
    }
    @media screen and (min-width: 1440px) {
      font-size: 100px;
    }
  }
  picture {
    width: 100%;
    display: block;
  }
  img {
    width: 100%;
    object-fit: cover;
    display: block;
    @media screen and (min-width: 992px) {
      aspect-ratio: 12/5;
    }
  }
</style>
